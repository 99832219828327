
import './App.css';

import Header from './Components/Header';

import { BrowserRouter, Route,Routes } from 'react-router-dom';
import Footer from './Components/Footer';
import SubFooter from './Components/SubFooter';
import NosFiliales from './Components/NosFiliales';
import Contact from './Components/Contact';
import Partenaire from './Components/Partenaire';
import About1 from './Components/About1';
import About2 from './Components/About2';
import About3 from './Components/About3';
import Carouselh from './Components/Carousel';
import IdhNetwork from './Components/IdhNetwork';
import AirlinesCompanies from './Components/AirlinesCompanies';
import BusinessTravelers from './Components/BusinessTravelers';
import TravelAgencies from './Components/TravelAgencies';
import Hotels from './Components/Hotels';
import Providers from './Components/Providers';
import GetInfo from './Components/GetInfo';

function App() {
  return (
    <div className="App" style={{}}>
      
      
      

      <BrowserRouter>
         
          <Routes>
            <Route path='/' element={<Header />} />
            <Route path='/Carousel' element={<Carouselh />} />
            <Route path='/About1' element={<About1 />} />
            <Route path='/About2' element={<About2 />} />
            <Route path='/About3' element={<About3 />} />
            <Route path='/GetInfo' element={<GetInfo />} />
          
            <Route path='/Partenaire' element={<Partenaire />} />
            <Route path='/Contact' element={<Contact />} />
            <Route path='/NosFiliales' element={<NosFiliales />} />
            <Route path='/FooterIdh' element={<Footer />} />
            <Route path='/subFooter' element={<SubFooter />} />
            <Route path='/IdhNetwork' element={<IdhNetwork />} />
            <Route path='/AirlinesCompanies' element={<AirlinesCompanies />} />
            <Route path='/BusinessTravelers' element={<BusinessTravelers />} />
            <Route path='/TravelAgencies' element={<TravelAgencies />} />
            <Route path='/Hotels' element={<Hotels />} />
            <Route path='/Providers' element={<Providers />} />
           
          </Routes>
      </BrowserRouter>
  
      
    </div>
  );
}

export default App;
