import { Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

import C from '../slide/hhh.jpg';
import c233 from '../slide/paraa.jpg';
import te from '../slide/ddd.jpg';
import Pdf from '../slide/demande.pdf';
import SubFooter from "./SubFooter";



function JoinUs(){
    return(
        <div className="vfgg" id="joinus" style={{marginTop:'8vw',marginBottom:'8vw'}}>
            <Container style={{margin:'auto'}}>
                            <div className="section-heading" style={{marginBottom:'9vw'}}>
								
                                    <span id="" className="" style={{fontSize:'32px', color:'rgb(6, 175, 191)'}}>A Fairly Simple Procedure</span>
                                    <h2 id="hhh"  style={{color:'rgb(3, 55, 210)',letterSpacing:'6px',marginBottom:'0vw'}}>HOW TO JOIN IDH NETWORK</h2>
                                    <span id=""  style={{ fontSize:'18px',fontWeight:'bold', color:'#868181',letterSpacing:'2px'}}>
                                    Please download, sign and seal the <br/> following documents before 
                                    sending them to us by e-mail <br/>

                                    <a href="mailto:Network.dz@groupIdh.com" style={{color: 'rgb(134, 129, 129)',textDecoration:'none'}}>Network.dz@groupIdh.com</a>
                                 
                                    </span>
                                   
                                  
                                    <div></div>
                            </div>
                <Row className="resf" style={{marginRight:'-40px',marginLeft:'15px'}}>
                    <Col>
                            <Card className="nbt" style={{ width: '20rem' , border:'none', backgroundColor:'inherit'}}>
                                 <img src={C} className="hzoom" alt="Idhtours"  style={{borderRadius:'10vw'}}/>
                                <Card.Body>
                                    <Card.Title id="ddddd">Demande</Card.Title>
                                    <Card.Text>
                                    Download and complete the membership application
                                    </Card.Text>
                                    <Button className="btn" variant="primary" style={{backgroundColor:'rgb(234, 61, 20)', border:'rgb(234, 61, 20)'}}>
                                        <a href={Pdf}><h4>Download</h4></a>
                                    </Button>
                                </Card.Body>
                            </Card>
                    </Col>
                    <Col>
                            <Card className="nbt" style={{ width: '20rem' , border:'none',backgroundColor:'inherit'}}>
                            <img src={te} className="hzoom" alt="Idhtours"  style={{borderRadius:'10vw'}}/>
                                        <Card.Body>
                                            <Card.Title id="dddd">Contrat  </Card.Title>
                                            <Card.Text>
                                            Download and sign the membership contract
                                            </Card.Text>
                                            <Button variant="primary" style={{backgroundColor:'rgb(234, 61, 20)', border:'rgb(234, 61, 20)'}}>
                                                <a href={Pdf}><h4>Download</h4></a>
                                            </Button>
                                        </Card.Body>
                            </Card>
                    </Col>
                    <Col>
                            <Card className="nbt" style={{ width: '20rem' , border:'none' , backgroundColor:'inherit'}}>
                            <img src={c233} className="hzoom" alt="Idhtours"  style={{borderRadius:'10vw'}}/>
                                        <Card.Body>
                                            <Card.Title id="dddddd" >Charte</Card.Title>
                                            <Card.Text>
                                            Sign the IDH NETWORK network charter
                                            </Card.Text>
                                            <Button variant="primary" style={{backgroundColor:'rgb(234, 61, 20)', border:'rgb(234, 61, 20)'}}>
                                            <a href={Pdf}><h4>Download</h4></a>
                                            </Button>
                                        </Card.Body>
                            </Card>
                    </Col>
                    
                    
                </Row>
            </Container>
           <SubFooter />
        </div>
        
    )
}

export default JoinUs;