import Video11 from '../slide/agencies.mp4';

function TravelAgencies(){
    return(
        <div style={{}}>
               <video  className=''src={Video11}   autoPlay={true} style={{width:'100%', height:'50vw'}}/>
        </div>
    )
}

export default TravelAgencies;