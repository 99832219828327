import Video11 from '../slide/Hotels.mp4';

function Hotels(){
    return(
        <div style={{}}>
               <video  className=''src={Video11}    autoPlay={true}  style={{width:'100%', height:'50vw'}}/>
        </div>
    )
}

export default Hotels;