import Video11 from '../slide/corporate.mp4';

function BusinessTravelers (){
    return(
        <div style={{}}>
               <video  className=''src={Video11}    autoPlay={true}  style={{width:'100%', height:'50vw'}}/>
        </div>
    )
}

export default BusinessTravelers;