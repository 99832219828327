import C from '../slide/cd.jpg';
import Col from 'react-bootstrap/Col';
import { Container, Row } from "react-bootstrap";


function AboutNetwork(){
    return(
        <div id='sght' style={{}}>
            
            <section className="services-22" style={{padding:'0px'}} >
                        
                        
                        
                        <Container className="container" >
                                <div className="section-heading" style={{}} >
                                    
                                    <span id="" className='' style={{height:'4vw',fontSize:'32px', color:'rgb(6, 175, 191)'}}>The 1st Worldwide</span>
                                    <h2 id="hhh"  style={{height:'4vw', color:'rgb(3, 55, 210)', letterSpacing:'6px'}}>network of travel agencies </h2>
                                   
                                </div>
                            <Row className="rowv">
                                
                                
                                <Col className="col" style={{}} id='oll'>

                                    <h1 className='h1idhh' id='idhidh' >About IDH Network </h1>
                                    <p className='pwhy' style={{padding:'60px'}}>
                                        The IDH NETWORK is the professional union network of travel agencies. As illustrated by its slogan 
                                        <br/> <br/>
                                        
                                        <span style={{color:'rgb(6, 175, 191)'}}>THE STRENGTH OF A GROUP
                                        <br/>
                                        THE AUTONOMY OF AN INDIVIDUAL </span> <br/> <br/> 
                                        
                                        IDH NETWORK aims to federate travel and tourism agencies the latter will offer you collective strength while promoting the investment and 
                                        independence of each agency, tourism professional, through its distinctions and innovations
                                        <br/><br/>
                                        IDH NETWORK defends the profession of travel agent and represents it at different levels it negotiates financial benefits with suppliers for its members, assists them in legal, tax and social matters, and also organizes training for its members

                                    </p>
                                    
                                </Col>
                                <Col className="col" id='ol' style={{}}>
                                
                                        <img src={C} alt="Idhtours"  />
                                </Col>
                                
                            </Row>
                        </Container>
                        
            </section>
                
         
        </div>
    );
}

export default AboutNetwork;