import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Mission(){
    return(
        <div className='qsd' id='mission' style={{backgroundPositionY: '-180px'}}>
            <Container>
                                <div className="section-heading" style={{marginTop:'8vw'}}>
								
                                    <span id="" className='' style={{fontSize:'32px', color:'rgb(6, 175, 191)'}}>Value Before Business</span>
                                    <h2 id="hhh"  style={{color:'rgb(3, 55, 210)',letterSpacing:'6px', marginBottom:'5vw'}}>We Offer the best</h2>
                                    <div></div>
                                </div>
                            <Row className=''>
                                <Col sm={4} className='kour'></Col>
        
                                <Col sm={8}  >

                                    
                                        <Col className='zoompart'>
                                            <h1 className='hh1idhh'>Partners Relationship</h1>
                                            <p> IDH NETWORK is  a brand contact with different worldwide known 
                                                partners specialized in tourism sector airline companies, hotels,
                                                 administrations... To negotiate and guarantee the best
                                                  offers and markets to our network members
                                            </p>
                                        </Col>
                                        <Col className='zoompart'>
                                            <h1 className='hh1idhh'>Documentation and E-services</h1>
                                            <p> Once you become a member of IDH NETWORK, with your 
                                                participant access you can acquire useful informations on our 
                                                websites: legal files, sales and standard documents
                                                
                                            </p>
                                        </Col>
                                
                                        <Col className='zoompart'>
                                            <h1 className='hh1idhh'>Training</h1>
                                            <p> IDH NETWORK provides you with the tailor-made training to 
                                                ensure the success of your agency and engage itself 
                                                to deploy the best of your competence 
                                            </p>
                                        </Col>
                                        <Col className='zoompart'>
                                            <h1 className='hh1idhh'>Assistance </h1>
                                            <p>Creative advice, development and expansion of your agency, legal aspects of your agency or company, we are always present for you 
                                                
                                            </p>
                                        </Col>
                                
               
                                        <Col className='zoompart'>
                                            <h1 className='hh1idhh'>Network Service</h1>
                                            <p> IDH NETWORK Helps to highlight your agency and the implementation
                                                 place of action plans allowing the development of sales. 

                                                Join us now and become a  member of a network of more than 5 000 offices around the world 
                                            
                                            </p>
                                        </Col>
                                        <Col className='zoompart'>
                                            <h1 className='hh1idhh'>IATA</h1>
                                            <p>After signing with IDH NETWORK we do offer assistance between your agency and IATA, agreement & presentation of balance sheet</p>
                                        </Col>
                                        <Col className='hdn'>   <h1 className='hh1idhh' style={{marginBottom:'0px'}}>Juridical</h1>
                                                <p >We do answer all your questions about your company and its 
                                                    activity customer disputes, suppliers, commercial leases
                                                </p>
                                     
                                        </Col>
                                </Col>



     


                         </Row>
            </Container>
        </div>
    );
}
export default Mission;