import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SubFooter from './SubFooter';

function Footer(){
	return(
		<div className='FooterIdh' >
			  
			  	<Container >
					
					<Row>
						<Col>
							<h1 className='sd' style={{marginBottom:'4vw',color:'rgb(3, 55, 210)'}}>GROUPIDH</h1>
							<p>
								
								
									The prospects for market development combined with the forward-looking 
									and ambitious vision of its Fondator Mr. Abdel OUBELLIL <br/>
									Quickly enriched the company’s offer through a large number of proposed
									 products and a variation of the domain activities

								 <br/><br/> 
								 
								 
								 </p>
						</Col>
						<Col>
							<h1 className='sd' style={{marginBottom:'4vw',color:'rgb(6, 175, 191)'}} 	>Useful Links</h1>
							<ul className='ulidhn'>
								<li><a href='#cta-11'><span>Group IDH</span></a> </li>
								<li><a href='#idhpartenaire'><span>NOS PARTENAIRES</span></a> </li>
								<li><a href='#idhfiliale'><span>Nos Filiales</span></a> </li>
								<li><a href='#contactid'><span>Contact</span></a> </li>
								<li><a href='IdhNetwork'><span>IDH Network</span></a> </li>
							</ul>
						</Col>
						
						<Col>
							<h1 className='sd' style={{marginBottom:'4vw',color:'rgb(3, 55, 210)'}} 	>Reach Us</h1>
							<ul className='ulidhn'>
								<li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokewidth="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-home"><path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/><polyline points="9 22 9 12 15 12 15 22"/></svg><span>Calle velazquez, Num.53 Planta 2 Puerta IZO, 28001 Madrid , Spain</span> </li>
								<li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokewidth="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone-forwarded"><polyline points="18 2 22 6 18 10"/><line x1="14" x2="22" y1="6" y2="6"/><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/></svg><span>DZA +213 550 248 608 </span></li>
								<li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokewidth="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail-minus"><path d="M22 15V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h8"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/><path d="M16 19h6"/></svg><span>contact@groupidh.com</span> </li>
								<li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokewidth="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone-forwarded"><polyline points="18 2 22 6 18 10"/><line x1="14" x2="22" y1="6" y2="6"/><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/></svg><span>ESP +34 689 177 003 </span> </li>
							</ul>
						</Col>
					</Row>
					
    			</Container>
				<SubFooter />
		</div>
		
	)
}

export default Footer;