import { Nav } from 'react-bootstrap';
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../slide/IDHNetwork.png";
import CarouselIdhNetwork from './CarouselIdhNetwork';
import AboutNetwork from './AboutNetwork';
import Services from './Services';
import Mission from './Mission';
import Navbar from 'react-bootstrap/Navbar';
import JoinUs from './JoinUs';

function IdhNetwork(){
    return(
        <div>
            <div style={{marginTop:'-10px', padding:'14px'}}>
            
            <div className='subhead' style={{borderBottom: '0.1px solid #f8f8f8',backgroundColor:'white'}}>
                 <Container fluid >
                    <Row >
                    <Col  className='p-0' style={{margin:'auto'}}>
                       
                       <Navbar expand="lg" className="bg-body-tertiary p-0"  style={{marginLeft:'12vw'}}>
                           <Container fluid style={{backgroundColor:'white'}} className=''>
                               <Navbar.Brand href="IdhNetwork"> <img src={Logo} alt="Logo" /></Navbar.Brand>
                               <Navbar.Toggle aria-controls="basic-navbar-nav" />
                               <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">

                                                <Nav.Link href="#sght"  className='linkc' id='link1'>Idh Network</Nav.Link>
                                                <Nav.Link href="#idhpartenaire" id='link2'>Our MISSIONS</Nav.Link>
                                                <Nav.Link href="#mission"  id='link3'>OUR SERVICES</Nav.Link>
                                                <Nav.Link href="#joinus" id='link4'>JOIN Us</Nav.Link>
                                                <Nav.Link href="/" id='link5' > group IDH </Nav.Link>
                                        
                                    </Nav>
                               </Navbar.Collapse>
                           </Container>
                       </Navbar>
                       



           </Col>




                        
    
                    </Row>
                </Container>
            </div>
          </div> 

        <CarouselIdhNetwork />
        <AboutNetwork />
        <Services />
        <Mission />
        <JoinUs />
       
        


        </div>
    );
}

export default IdhNetwork;