
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";




import Img45 from '../slide/45678.png';

function About(){
    return(
       
            <section className="services-2">
				<Container className="container">
					<Row className="row">
								<Col sm={12} className="colmd12">
										<span style={{textAlign:'center',color:'#164194'}}
										><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokewidth="2" strokelinecap="round" strokelinejoin="round" className="lucide lucide-quote"><path d="M3 21c3 0 7-1 7-8V5c0-1.25-.756-2.017-2-2H4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2 1 0 1 0 1 1v1c0 1-1 2-2 2s-1 .008-1 1.031V20c0 1 0 1 1 1z"/><path d="M15 21c3 0 7-1 7-8V5c0-1.25-.757-2.017-2-2h-4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2h.75c0 2.25.25 4-2.75 4v3c0 1 0 1 1 1z"/></svg></span>
										<p id="hj">

										Market developments have pushed IDH GROUP to move from a wholesaler, supplier of Products, 
										to a supplier of technical and technological solutions adapted to customer needs. 
										A complete solution requires the provision of all necessary 
										logistics as well as all associated services
										
										</p>
								</Col>

								<Col sm={12} className="ns">
										<h2 className="bv" style={{ color:'rgb(6, 175, 191)'}}>anytime anywhere <br/><br/> </h2>
										<p className='bvv'>

										GROUP IDH is not just a travel agency it's a community of passionate explorers bound by a shared love for adventure. We stands as a beacon for like-minded individuals seeking unforgettable experiences. Our team of seasoned experts combines expertise with a genuine enthusiasm for discovering new horizons, ensuring every journey is a seamless blend of exploration and camaraderie. We do transform travel into a shared narrative of unforgettable memories.
											<br/> <br/>
											
										</p>
								</Col>

								<Col sm={12} className="colmd4" >
                                    <img src={Img45} alt="Idhtours" className="imh"/>


								</Col>
									
									
									
					</Row>
				</Container>
			</section>
		
		
  
    )
}

export default About;