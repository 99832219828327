import Container from "react-bootstrap/esm/Container";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import C from '../slide/blaaa.gif';



function About3(){
    return(
        <Container style={{marginBottom:'8vw'}}>
                                            <Row>

                                                
                                                <Col >

                                                    <h1 className='h1idhh' >Our Mission </h1>
                                                    <p className='pwhy'>Today, much more than offering simple online platforms, we offer a 
                                                    variety of services dedicated to our different customers with a rigorous selection of suppliers 
                                                    and products. To celebrate its 20th anniversary IDH Group launch for the occasion a new site endowed 
                                                    with artificial intelligence 
                                                    
                                                    <br/> <br/> 
                                                    <a className="h1idhhh" href="https://www.elcomparador-b2b.com/" 
                                                    style={{textDecoration:"none",fontSize:"20px"}}>www.elcomparador-b2b.com
                                                    </a> 
                                                    <br/> <br/> 

                                                    The first global comparator of travel and tourism products by developing a very optimal account security system, allowing B2B customers to compare between the largest international suppliers and thus carry out their secure reservations. Each and every detail is intended and planned in advance to manage any probable risk and ensure the smooth running of the booking process
                                                    </p>
                                                                                
                                                </Col>
                                                <Col>
                                                    <img src={C} alt="" className="about1"  /> 

                                                </Col>
                                                
                                            </Row>
        </Container>
    );
}

export default About3;