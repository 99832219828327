import { Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Img4 from '../slide/hhhhhh.jpg';
import Hotel from '../slide/ccc.jpg';
import C from '../slide/cc.jpg';
import c233 from '../slide/hhhhh.jpg';
import te from '../slide/cccc.jpg';








function Partenaire(){
    return(
        <div className="vfg" id="idhpartenaire">
            <Container style={{margin:'auto'}}>
                <div className="section-heading">
								
                                    <span id=""  style={{fontSize:'32px', color:'rgb(6, 175, 191)',letterSpacing:'2px'}}>OUR PARTNERS </span>
                                    <h2 id="hhh"  style={{color:'rgb(3, 55, 210)',letterSpacing:'6px'}}>Around The World</h2>
                                    <div></div>
                </div>
                <Row style={{}}>
                    <Col>
                            <Card style={{  border:'none', backgroundColor:'inherit', margin:'auto',marginBottom:'4vw'}}>
                                <img className="hzoom" src={C} alt="Idhtours"  style={{borderRadius:'10vw'}}/>
                                <Card.Body>
                                    <Card.Title id="ddddd">Airlines Companies</Card.Title>
                                    <Card.Text>
                                    IDH GROUP  works with over 1000 Airlines Companies around the world
                                    </Card.Text>
                                  
                                    <Button className="btn" variant="primary"  style={{backgroundColor:'rgb(234, 61, 20)', border:'rgb(234, 61, 20)'}}>
                                        <a href='./AirlinesCompanies'><h4>See more</h4></a>
                                    </Button>
                                </Card.Body>
                            </Card>
                    </Col>
                    <Col>
                            <Card style={{  border:'none',backgroundColor:'inherit', margin:'auto',marginBottom:'4vw'}}>
                                        <img src={te}  className="hzoom" alt="Idhtours"  style={{borderRadius:'10vw'}}/>
                                        <Card.Body>
                                            <Card.Title id="dddd">Business Travelers </Card.Title>
                                            <Card.Text>
                                            Assistance & Flexibility to nearly 4000 Business travelers in different companies
                                            </Card.Text>
                                            <Button variant="primary" style={{backgroundColor:'rgb(234, 61, 20)', border:'rgb(234, 61, 20)'}}>
                                          {/*  <a href={Video2}><h4>See more</h4></a>*/}
                                          <a href='./BusinessTravelers'><h4>See more</h4></a>
                                            </Button>
                                        </Card.Body>
                            </Card>
                    </Col>
                    <Col>
                            <Card style={{border:'none' , backgroundColor:'inherit', margin:'auto',marginBottom:'4vw'}}>
                                        <img src={c233}  className="hzoom" alt="Idhtours"  style={{borderRadius:'10vw'}}/>
                                        <Card.Body>
                                            <Card.Title id="dddddd" >Travel Agencies</Card.Title>
                                            <Card.Text>
                                            + 5 000 Travel Agencies trusted IDH GROUP  over the years JOIN them
                                            </Card.Text>
                                            <Button variant="primary" style={{backgroundColor:'rgb(234, 61, 20)', border:'rgb(234, 61, 20)'}}>
                                            <a href='./TravelAgencies'><h4>See more</h4></a>
                                            </Button>
                                        </Card.Body>
                            </Card>
                    </Col>
                    <Col>
                            <Card style={{  border:'none' , backgroundColor:'inherit',margin:'auto', margin:'auto'}}>
                                        <img src={Hotel}  className="hzoom" alt="Idhtours"  style={{borderRadius:'10vw'}}/>
                                        <Card.Body>
                                            <Card.Title id="dddddd" >Hotels</Card.Title>
                                            <Card.Text>
                                            We do work with over 1 Million Hotels all over the world 
                                            </Card.Text>
                                            <Button variant="primary" style={{backgroundColor:'rgb(234, 61, 20)', border:'rgb(234, 61, 20)'}}>
                                            <a href='./Hotels'><h4>See more</h4></a>
                                            </Button>
                                        </Card.Body>
                            </Card>
                    </Col>
                    <Col>
                            <Card style={{ border:'none' , backgroundColor:'inherit', margin:'auto'}}>
                                        <img src={Img4}  className="hzoom" alt="Idhtours"  style={{borderRadius:'10vw'}}/>
                                        <Card.Body>
                                            <Card.Title id="dddddd" >Providers</Card.Title>
                                            <Card.Text>
                                            Discover with us our over 40 providers until now
                                          
                                            </Card.Text>
                                            <Button variant="primary" style={{backgroundColor:'rgb(234, 61, 20)', border:'rgb(234, 61, 20)'}}>
                                            <a href='./Providers'><h4>See more</h4></a>
                                            </Button>
                                        </Card.Body>
                            </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Partenaire;