import Container from "react-bootstrap/esm/Container";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import C from '../slide/89555.gif';



function About2(){
    return(
        <Container style={{marginBottom:'8vw'}}> 
                                            <Row>
                                                <Col>
                                                    <img src={C} alt="" className="about1"  /> 

                                                </Col>
                                                <Col >

                                                <h1 className='h1idhh' >evolution of IDH Group</h1>
                                                <p className='pwhy'>
                                                            By its dynamism, vitality, evolution and development
                                                            IDH GROUP is expert in e-tourism since the early 2000s, 
                                                            presents itself as the provider of comprehensive, reliable 
                                                            and sustainable integrated offers in all areas of activity in 
                                                            which it operates tourism for individuals and companies.
                                                            <br/> <br/> 
                                                            IDH Group confirms its ambition by positioning itself as a leader in the e-tourism market but above all as a supplier of all associated technological solutions
                                                </p>
                                                                    
                                                </Col>
                                                
                                            </Row>
        </Container>
    );
}

export default About2;