import Carousel from 'react-bootstrap/Carousel';
import Video from '../slide/4.mp4';

function CarouselIdhNetwork(){
    return(
        <div>
            <Carousel style={{marginTop:'-18px',marginBottom:'2vw'}}>
                <Carousel.Item>
                        <video  className='ItemImg'src={Video}   autoPlay={true}/>
                    <Carousel.Caption style={{marginTop:'-35vw'}}>
                        <h3 className='idh3' style={{fontSize: '2.9rem'}}> </h3>
                    </Carousel.Caption>
                </Carousel.Item>
                    
                    
                
                
            </Carousel>
        </div>
    );
}

export default CarouselIdhNetwork;