
import { Nav } from 'react-bootstrap';
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../slide/idhgroupidh.png";
import Footer from './Footer';
import NosFiliales from './NosFiliales';
import Contact from './Contact';
import About from './About';
import Partenaire from './Partenaire';
import Carouselh from './Carousel';
import About1 from './About1';
import About2 from './About2';
import About3 from './About3';
import Navbar from 'react-bootstrap/Navbar';






function Header(){
    return(
        <div style={{}}>
       
        <div className='subhead' style={{padding:'14px'}}>
             <Container fluid >
                <Row style={{}} className=''>
                   
                    <Col  className='p-0' style={{margin:'auto'}}>
                       
                                <Navbar expand="lg" className="bg-body-tertiary p-0"  style={{marginLeft:'12vw'}}>
                                    <Container fluid style={{backgroundColor:'white'}} className=''>
                                        <Navbar.Brand href="./"> <img src={Logo} alt="Logo" /></Navbar.Brand>
                                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                        <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="me-auto">
                                        <Nav.Link href="#cta-11"  className='linkc' id='link1'>Group Idh</Nav.Link>
                                            <Nav.Link href="#idhpartenaire" id='link2'>OUR PARTNERS</Nav.Link>
                                            <Nav.Link href="#idhfiliale"  id='link3'>OUR AFFILIATES</Nav.Link>
                                            <Nav.Link href="#contactid" id='link4'>CONTACT</Nav.Link>
                                            <Nav.Link href="IdhNetwork" id='link5' > IDH NETWORK</Nav.Link>
                                            
                                        </Nav>
                                        </Navbar.Collapse>
                                    </Container>
                                </Navbar>
                                



                    </Col>

                </Row>
            </Container>
        </div>
       
       
        <Carouselh />
       
        <About1 />
        <About2 />
        <About3 />
        <Partenaire />
        <About />
        <Contact />
        <NosFiliales />
        <Footer />
        

        </div>
    )
}

export default Header; 