



function NosFiliales(){
    return(
        
           
            <section id="idhfiliale" className="clients" style={{marginTop:'0px'}}>
					<div className="container">
						<div className="row">
							<div className="section-heading" id="idnn">
								
								<span id=""  style={{fontSize:'32px', color:'rgb(6, 175, 191)',letterSpacing:'2px'}}>Deliciously near you</span>
									<h2 id="hhh"  style={{color:'rgb(3, 55, 210)', letterSpacing:'6px'}}>Also Present On</h2>
								<div></div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12" style={{marginLeft:'50px',marginRight:'50px'}}>
								
									<div className="item" id="item1" style={{marginTop:'-8vw',marginLeft:'2vw',width:'19vw'}} >
                                       
									</div>
									<div className="item" id="item2" style={{marginTop:'3vw'}} >
                                      
									</div>
									<a href="https://www.hotelidh.com/">
									<div className="item" id="item3" style={{marginTop:'-6vw'}}>
                                      
									</div></a>
									<div className="item" id="item4" style={{marginTop:'2vw'}}>
                                       
									</div>

									<a href="https://www.idhcorporate.com">
									<div className="item" id="item5" style={{marginTop:'-4.3vw'}}>
                                       
									</div></a>
									
									<a href="IdhNetwork">
									<div className="item" id="item6" style={{width:'31vw', backgroundSize:'cover'}}>
                                        
									</div></a>
									<a href="https://www.elcomparador-b2b.com/">	<div className="item" id="item7" style={{marginRight:'-35px'}}>
                                       
									   </div> </a>
									
									
								
							</div>
						</div>
					</div>
				</section>

              
       
    )
}

export default NosFiliales;