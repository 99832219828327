import { Nav } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../slide/idhgroupidh.png";
import GetInfoTxt from './GetInfoTxt';
import SubFooter from './SubFooter';

function GetInfo(){
    return(
        <div>
           <div className='subhead' style={{padding:'14px'}}>
             <Container fluid >
                <Row style={{}}>
                   
                    <Col  className='p-0' style={{margin:'auto'}}>
                       
                                <Navbar expand="lg" className="bg-body-tertiary p-0"  style={{marginLeft:'12vw'}}>
                                    <Container fluid style={{backgroundColor:'white'}} className=''>
                                        <Navbar.Brand href="./"> <img src={Logo} alt="Logo" /></Navbar.Brand>
                                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                        <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="me-auto">
                                        <Nav.Link href="/#cta-11"  className='linkc' id='link1'>Group Idh</Nav.Link>
                                            <Nav.Link href="Partenaire/#idhpartenaire" id='link2'>OUR PARTNERS</Nav.Link>
                                            <Nav.Link href="NosFiliales/#idhfiliale"  id='link3'>OUR AFFILIATES</Nav.Link>
                                            <Nav.Link href="/Contact/#contactid" id='link4'>CONTACT</Nav.Link>
                                            <Nav.Link href="IdhNetwork" id='link5' > IDH NETWORK</Nav.Link>
                                            
                                        </Nav>
                                        </Navbar.Collapse>
                                    </Container>
                                </Navbar>
                                



                    </Col>

                </Row>
            </Container>
        </div>

        

        <GetInfoTxt />                        
        <SubFooter />                        
        </div>
    );
}

export default GetInfo;