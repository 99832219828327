import Container from "react-bootstrap/esm/Container";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import C from '../slide/ccccc.jpg';



function GetInfoTxt(){
    return(
        <Container style={{marginTop:'6vw'}}> 
                                            <Row>
                                                <Col>
                                                    <img src={C} alt="" className="about1" style={{borderRadius:'10vw'}} /> 

                                                </Col>
                                                <Col >

                                                <h1 className='h1idhh' style={{marginTop:'6vw'}} >Thank you for your message 
                                                
                                                <br/> <br/> It has successfully been sent
                                                </h1>
                                                
                                                                    
                                                </Col>
                                                
                                            </Row>
        </Container>
    );
}

export default GetInfoTxt;