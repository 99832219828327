import Container from "react-bootstrap/esm/Container";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import C from '../slide/9688.png';



function About1(){
    return(
        <Container style={{marginBottom:'10vw'}} className="about11">

                                            <div id="cta-11" className="Col-heading" style={{height:'22vw'}}>
                                            
                                                <span id="d55"  style={{fontSize:'32px', color:'rgb(6, 175, 191)',letterSpacing:'2px'}}>Why to Join</span>
                                                <h2 id="hhh"  style={{color:'rgb(3, 55, 210)', marginBottom:'20vw',letterSpacing:'6px'}}>Idh Group</h2>
                                                <div></div>
                    
                                             </div>



                                            <Row>
                                                <Col >

                                                        <h1 className='h1idhh' >History of IDH Group</h1>
                                                        <p className='pwhy'>
                                                            
                                                                    IDH TOURS was founded in the early 2000s. 
                                                                    Travel provider and various tourism products flight ticket, hotels, 
                                                                    excursions and transfers in B2B as well as B2C. it aims to promote
                                                              
                                                                    The Algerian destination
                                                                    <br/><br/>
                                                                    

                                                                    Our team of professionals dedicated and passionate about tourism and travel 
                                                                    has the main objective of ensuring that the discovery of the world is accessible 
                                                                    to all by offering a multitude of tourist circuits in line with all their expectations
                                                                    
                                                                    <br/> <br/> We do offer high quality services at competitive prices for a world without borders
                                                        </p>
                                                                    
                                                </Col>
                                                <Col>
                                                    <img src={C} alt="" className="about1"  /> 

                                                </Col>
                                            </Row>
        </Container>
    );
}

export default About1;