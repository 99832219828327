
import React , {useState} from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form } from "react-bootstrap";


function Contact(){


    const [nom, setNom]=useState("")
    const [email, setEmail]=useState("")
    const [message, setMessage]=useState("")
    const navigate =useNavigate();

    async function signUp()
    {
        //console.warn(nom,email,message)
        let item={nom,email,message}
        console.warn(item)

        let result = await fetch("http://localhost:8000/api/register",{
            method:'POST',
            body:JSON.stringify(item),
            headers:{
                "Content-Type":'application/json',
                "Accept":'application/json'
            }
        })

        result =await result.json()
        //console.warn("result",result)
        localStorage.setItem("user-info",JSON.stringify(result))
        navigate('/GetInfo');
       
    }


    return (
                
               
                  
                <Container className='form_sub' id='contactid'>
                        
                            <div className="section-heading">
								
                                    <span id=""  style={{fontSize:'32px', color:'rgb(6, 175, 191)',letterSpacing:'2px'}}>Talk to us</span>
                                    <h2 id="hhh"  style={{color:'rgb(3, 55, 210)', marginBottom:'5vw',letterSpacing:'6px'}}>Get in Touch</h2>
                                    <div></div>
                            </div>
						

                        <Row >
                            <Col  style={{}}>
                                <Form style={{marginBottom:'4vw'}}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      
                                        <Form.Control type="text" placeholder="Your Name" onChange={(e)=>setNom(e.target.value)} value={nom} style={{marginBottom:"2vw"}} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                                        
                                        <Form.Control type="email" placeholder="nom@example.com" onChange={(e)=>setEmail(e.target.value)} value={email} style={{marginBottom:"2vw"}}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        
                                        <Form.Control as="textarea" rows={10} placeholder='Your message...' onChange={(e)=>setMessage(e.target.value)}  value={message} style={{marginBottom:"2vw"}}/>
                                    </Form.Group>

                                    <>
                                    <style type="text/css">
                                        {`
                                    .btn-flat {
                                    background-color: #F14820;
                                    color: white;
                                    float:left;
                                    }

                                    .btn-xxl {
                                    padding: 1rem 1.5rem;
                                    font-size: 1.5rem;
                                    }
                                    `}
                                    </style>

                                    <Button variant="flat" size="xxl" onClick={signUp} >
                                    Send
                                    </Button>
                                    </>
                                </Form>
                            </Col>


                            <Col  style={{margin:'auto',marginTop:'8vw'}} className='msde' >
                                    <h4 className='dd' style={{marginBottom:'15px',fontSize:'25px'}} id='ddd'>Contact Us Anytime</h4>
                                    <div className="line-dec"></div>
                                        <ul className='ulcontact'>
                                             <li style={{fontSize:'18px'}}><i className=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-map-pin"><path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"/><circle cx="12" cy="10" r="3"/></svg></i>Calle velazquez, Num.53 Planta 2
											        Puerta IZO, 28001 Madrid , Spain </li>
											
											<li><i className=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/></svg></i>ESP +34 689 177 003 </li>
											<li><i className=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/></svg></i>TUN +216 236 38 98</li>
											<li><i className=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/></svg></i>DXB +971 589 854 107  </li>
											<li><i className=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/></svg></i>DZA +213 550 248 608  </li>

											<li><i className=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg></i> <a style={{color:'#888888',textDecoration:'none'}} href="mailto:contact@groupidh.com" >contact@groupidh.com</a> </li>
											<li><i className=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg></i> <a style={{color:'#888888',textDecoration:'none'}}  href="mailto:contact@groupidh.com">agency.network@groupidh.com</a> </li>
                                        </ul>
                            </Col>
                        </Row>
                </Container>
           
          
                
      );
}



export default Contact;