import Video11 from '../slide/idh.mp4';

function Video6(){
    return(
        <div style={{}}>
               <video  className=''src={Video11}  autoPlay muted loop={true} style={{width:'100%'}}/>
        </div>
    )
}

export default Video6;