import Video1 from '../slide/950.mp4';

function AirlinesCompanies(){
    return(
        <div style={{}}>
               <video  className=''src={Video1}   autoPlay={true}   style={{width:'100%', height:'50vw'}}/>
        </div>
    )
}

export default AirlinesCompanies;