



import Carousel from 'react-bootstrap/Carousel';
import Video6 from '../Components/Video6';




function Carouselh() {
  return(
    <Carousel style={{marginBottom:'4vw',marginTop:'-8px'}}>
    <Carousel.Item>
  
      <Video6 />
      <Carousel.Caption style={{marginTop:'-35vw'}}>
      <h3 className='idh3' style={{fontSize: '2.9rem'}}>Compare & Book with the Leader </h3>
      
      
      </Carousel.Caption>
    </Carousel.Item>
    
    
  </Carousel>
  )
}
export default Carouselh;