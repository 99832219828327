import { Container, Row } from "react-bootstrap";

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';


import C from '../slide/d5.png';
import te from '../slide/d6.png';
import c233 from '../slide/d7.png';




function Services(){
    return(
        <div className="vfg" id="idhpartenaire">
            <Container style={{margin:'auto'}}>
                                <div className="section-heading">
								
                                    <span id="" className="" style={{fontSize:'32px', color:'rgb(6, 175, 191)',letterSpacing:'2px'}}>Our Missions</span>
                                    <h2 id="hhh"  style={{color:'rgb(3, 55, 210)',letterSpacing:'6px'}}>Always Here For You</h2>
                                    <div></div>
                                </div>
                <Row style={{}}>
                    <Col>
                            <Card style={{   backgroundColor:'inherit', margin:'auto',marginBottom:'2vw'}} id="ddm">
                                 <img src={C} alt="Idhtours"  style={{}}/>
                                <Card.Body>
                                    <Card.Title id="ddddd" style={{color:'rgb(234, 61, 20)'}}>REPRESENT</Card.Title>
                                    <Card.Text style={{color: 'black',fontSize: '19px'}}>
  
                                    
                                    
                                    African and worldwide level <br/>
                                    National level -
                                    Local level


                                    </Card.Text>
                                   
                                </Card.Body>
                            </Card>
                    </Col>
                    <Col>
                            <Card style={{ backgroundColor:'inherit', margin:'auto',marginBottom:'2vw'}} id="dddm">
                            <img src={te} alt="Idhtours"  style={{}}/>
                                        <Card.Body>
                                            <Card.Title id="dddd" style={{color:'rgb(234, 61, 20)'}}>ADVISE   </Card.Title>
                                            <Card.Text style={{color: 'black',fontSize: '19px'}}>
                                            We do answer the needs of participants and analyze the results,
                                            we always offer the best in all areas
                                            </Card.Text>
                                           
                                        </Card.Body>
                            </Card>
                    </Col>
                    <Col>
                            <Card style={{backgroundColor:'inherit', margin:'auto',marginBottom:'2vw'}} id="ddmm">
                                        <img src={c233} alt="Idhtours"  style={{}}/>
                                        <Card.Body>
                                            <Card.Title id="dddddd" style={{color:'rgb(234, 61, 20)'}}>PROCEED </Card.Title>
                                            <Card.Text style={{color: 'black',fontSize: '19px'}}>
                                            
                                                
                                                With public power
                                                With great providers
                                                With syndical organizations


                                            </Card.Text>
                                          
                                        </Card.Body>
                            </Card>
                    </Col>
                    
                </Row>
            </Container>
        </div>
    )
}

export default Services;